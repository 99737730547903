/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './NativeSelectInput.module.scss';

export type Props = {
  /**
   * The option elements to populate the select with.
   * Can be some `<option>` elements.
   */
  children?: React.ReactNode;
  /**
   * The CSS class name of the select element.
   */
  className?: string;
  /**
   * If `true`, the select will be disabled.
   */
  disabled?: boolean;
  /**
   * The icon that displays the arrow.
   */
  IconComponent: React.ComponentType<any> | string;
  /**
   * Use that prop to pass a ref to the native select element.
   * @deprecated
   */
  inputRef?: React.Ref<any>;
  /**
   * @ignore
   */
  multiple?: boolean;
  /**
   * Name attribute of the `select` or hidden `input` element.
   */
  name?: string;
  /**
   * Callback function fired when a menu item is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange?: (event: React.ChangeEvent) => unknown;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => unknown;
  /**
   * The input value.
   */
  value?: any;
  classes?: {
    select?: string;
  };
};

export const NativeSelectInput = React.forwardRef<HTMLSelectElement, Props>(
  function NativeSelectInput(props, ref) {
    const { className, disabled, IconComponent, inputRef, classes, ...other } = props;

    return (
      <React.Fragment>
        <select
          className={cx(
            styles.select,
            classes?.select,
            {
              [styles.disabled]: disabled,
            },
            className
          )}
          disabled={disabled}
          ref={inputRef || ref}
          {...other}
        />
        {props.multiple ? null : (
          <IconComponent
            className={cx(styles.icon, {
              [styles.disabled]: disabled,
              className,
            })}
          />
        )}
      </React.Fragment>
    );
  }
);
