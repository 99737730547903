/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import logger from '@/utils/logger';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';

export const reportCitrusClick = async (id: string) => {
  try {
    const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;
    const axios = getAxiosForClient();
    const { baseURL, axiosConfig } = getCitrusProxyConfiguration();
    !CITRUS_PROXY_DISABLED
      ? await axios.get(`${baseURL}/v1/resource/second-c/${id}`, axiosConfig)
      : await axios.post('/api/citrus/click', {
          id,
        });
  } catch (error) {
    logger.error({
      message: 'Error while reporting Citrus impression (FE)',
      meta: {
        error,
      },
    });
  }
};
