/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { YmmeVehicleResponse, YmmeVehicleData } from '../interface';
import { useCallback } from 'react';
import { vehicleSort } from '../utils/vehicleSort';
import { requestBaseURL } from '@/config/serviceAPI';
import { AxiosInstance } from 'axios';

const getVehicles = async (axiosInstance?: AxiosInstance) => {
  const response = await getAxios(axiosInstance).get<YmmeVehicleResponse>(
    `${requestBaseURL}/ecomm/b2c/v1/currentUser/vehicles`
  );
  return selector(response.data);
};

export const getKey = () => ['user', 'vehicles'];

export const selector = (data: YmmeVehicleResponse): YmmeVehicleData => {
  return {
    vehiclesInfo: vehicleSort(data.vehiclesInfo),
  };
};

type Options = {
  onSuccess?: () => void;
};

export const useVehicles = ({ onSuccess }: Options = {}) => {
  const query = useQuery({
    queryKey: getKey(),
    queryFn: () => getVehicles(),
    onSuccess,
  });

  return query;
};

const staleTime = 30 * 60000; // 30 minutes

export const useVehicle = (vehicleId: string) => {
  const query = useQuery({
    enabled: !!vehicleId,
    queryKey: getKey(),
    queryFn: () => getVehicles(),
    select: useCallback(
      (vehicles: YmmeVehicleResponse) =>
        vehicles.vehiclesInfo.find((vehicle) => vehicle.vehicleId === vehicleId),
      [vehicleId]
    ),
    staleTime,
  });

  return query;
};

export const prefetchVehiclesData = (queryClient: QueryClient, axiosInstance: AxiosInstance) => {
  return queryClient.prefetchQuery({
    queryKey: getKey(),
    queryFn: () => getVehicles(axiosInstance),
  });
};
