/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './Star.module.scss';
import cx from 'classnames';

type StarProps = {
  /**
   * Value from 0 to 100 describing the percentage of filled star
   */
  percent?: number;
  isOosOrDoesNotFit?: boolean;
  isCertonaPartCard?: boolean;
};

const filledColor = '#f26100';
const emptyColor = 'rgba(0, 0, 0, 0.26)';

export function Star({
  percent = 100,
  isOosOrDoesNotFit = false,
  isCertonaPartCard = false,
}: StarProps) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      className={cx({
        [styles.root]: !isOosOrDoesNotFit,
        [styles.certonaPartCard]: isCertonaPartCard,
      })}
      aria-hidden="true"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2647 4.68047C13.1818 4.44374 12.9604 4.28059 12.7059 4.26867H8.70599L7.43616 0.412711C7.34574 0.154196 7.0917 -0.0139352 6.81394 0.000909543C6.54203 -0.00100991 6.30035 0.170802 6.21712 0.425189L4.94729 4.28114H0.960028C0.692197 4.27934 0.453242 4.44616 0.366818 4.69529C0.280394 4.94442 0.365896 5.21994 0.579079 5.37928L3.81714 7.78769L2.54731 11.6686C2.45761 11.9234 2.5483 12.2058 2.77054 12.3639C2.99279 12.522 3.29415 12.5183 3.51238 12.3549L6.90283 9.85918L10.306 12.3549C10.412 12.4342 10.541 12.4779 10.6742 12.4797C10.877 12.4825 11.0684 12.3881 11.1872 12.2266C11.306 12.0651 11.3373 11.8569 11.271 11.6686L10.0012 7.78769L13.1758 5.3668C13.3469 5.17808 13.3823 4.90531 13.2647 4.68047Z"
        fill={emptyColor}
        strokeLinejoin="round"
      />
      <path
        d="M13.2647 4.68047C13.1818 4.44374 12.9604 4.28059 12.7059 4.26867H8.70599L7.43616 0.412711C7.34574 0.154196 7.0917 -0.0139352 6.81394 0.000909543C6.54203 -0.00100991 6.30035 0.170802 6.21712 0.425189L4.94729 4.28114H0.960028C0.692197 4.27934 0.453242 4.44616 0.366818 4.69529C0.280394 4.94442 0.365896 5.21994 0.579079 5.37928L3.81714 7.78769L2.54731 11.6686C2.45761 11.9234 2.5483 12.2058 2.77054 12.3639C2.99279 12.522 3.29415 12.5183 3.51238 12.3549L6.90283 9.85918L10.306 12.3549C10.412 12.4342 10.541 12.4779 10.6742 12.4797C10.877 12.4825 11.0684 12.3881 11.1872 12.2266C11.306 12.0651 11.3373 11.8569 11.271 11.6686L10.0012 7.78769L13.1758 5.3668C13.3469 5.17808 13.3823 4.90531 13.2647 4.68047Z"
        fill={filledColor}
        clipPath={`polygon(0% 0%, ${percent}% 0%, ${percent}% 100%, 0% 100%)`}
        strokeLinejoin="round"
      />
    </svg>
  );
}
