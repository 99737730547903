/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Rating } from '../../Rating/Rating';
import styles from './styles.module.scss';
import { useFormatCompactNumber } from '@/hooks/useFormatCompactNumber';

type Props = {
  averageRating: string;
  totalReviews: string;
  hideAverageRatingNumber?: boolean;
  containerClassName?: string;
  showStarFractions?: boolean;
};

const RatingsReview = (props: Props) => {
  const {
    averageRating,
    totalReviews,
    hideAverageRatingNumber = false,
    containerClassName,
    showStarFractions = false,
  } = props;
  const formattedTotalReviews = useFormatCompactNumber(totalReviews);

  return (
    <Grid
      container
      className={cx(styles.ratingsContainer, containerClassName)}
      alignItems="center"
      justify="flex-start"
      spacing={0}
    >
      <Grid item>
        <Rating value={Number(averageRating)} showStarFractions={showStarFractions} />
      </Grid>
      {hideAverageRatingNumber ? null : (
        <Grid item>
          <span className={cx(azCommonStyles['az-caption'], styles.ratingNumber)}>
            {Number(averageRating).toFixed(1)}
          </span>
        </Grid>
      )}
      <Grid item>
        <span className={cx(azCommonStyles['az-caption'], styles.ratingReviews)}>
          ({totalReviews?.length > 3 ? formattedTotalReviews : totalReviews})
        </span>
      </Grid>
    </Grid>
  );
};

export default RatingsReview;
