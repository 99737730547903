/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';

export const replaceHistoryStateOptionsOnClick = (attribute: string, value: unknown) => {
  const tempState = root.history.state as Record<string, unknown> & {
    options: Record<string, unknown>;
  };
  tempState.options[attribute] = value;

  root.history.replaceState(tempState, root.document.title);
};
