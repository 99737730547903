/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const myVehicleConstant = {
  viewMoreDesktopBtn: 'viewMoreDesktopBtn',
  SERVICE_HISTORY: 'SERVICE_HISTORY',
  serviceHistory: 'serviceHistory',
  MAINTENANCE: 'MAINTENANCE',
  maintenanceIntervals: 'maintenanceIntervals',
  VEHICLE_REPAIR: 'VEHICLE_REPAIR',
  repairGuides: 'repairGuides',
  RECALLS: 'RECALLS',
  recalls: 'recalls',
  myVehicles: 'myVehicles',
  search: 'search',
  vehicleModalDialog: 'launch add a vehicle modal dialog',
  REPAIR: 'REPAIR',
  repairGuide: 'repairGuide',
  editVehicle: 'editVehicle',
  normal: 'normal',
  severe: 'severe',
  mileageRatePerDay: 'mileageRatePerDay',
  mileageRatePerWeek: 'mileageRatePerWeek',
  mileageRatePerMonth: 'mileageRatePerMonth',
  mileageRatePerYear: 'mileageRatePerYear',
  Header: 'Header',
  updateField: 'UPDATE_FIELD',
  currentYear: 'currentYear',
  currentMake: 'currentMake',
  currentModel: 'currentModel',
  currentEngine: 'currentEngine',
  initialYear: 'initialYear',
} as const;
