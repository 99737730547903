/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { VehiclesInfo } from '../interface';

export const vehicleSort = (vehiclesInfo: VehiclesInfo[]): VehiclesInfo[] => {
  // if no match this will be an empty array
  const preferredVehicleList = vehiclesInfo.filter((vehicle) => vehicle.preferredVehicle);
  const sortedInfo = [...vehiclesInfo]
    .filter((vehicle) => !vehicle.preferredVehicle)
    .sort((vehicleData, nextVehicleData): number => {
      // sort based on year, if equal use name
      if (vehicleData.year === nextVehicleData.year) {
        return vehicleData.vehicleName.toLowerCase() < nextVehicleData.vehicleName.toLowerCase()
          ? -1
          : 1;
      }
      return vehicleData.year > nextVehicleData.year ? -1 : 1;
    });
  return [...preferredVehicleList, ...sortedInfo];
};
