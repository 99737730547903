/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import root from 'window-or-global';
import cx from 'classnames';
import { Grid } from '../../Grid';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { historyStateOptionAttributes } from '../../../constants/historyStateOption';
import Image from '@/components/NextImage';
import NextImage from '@/components/NextImage';
import RatingsReview from '../../AZCustomComponent/RatingsReview';
import { useActiveElement } from '../../../hooks/useActiveElement';
import Price from '../Price';
import { useRouter } from 'next/router';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import type { CertonaPricing, AddToCartData } from '@/types';
import { Button } from '@/components/Button';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { routePaths } from '../../../constants/routePaths';
import { SmartLink as Link } from '../../../utils/smartLink';
import { useFeatureFlag } from '@/features/globalConfig';
import { reportCitrusClick } from '@/features/citrus/utils/reportCitrusClick';
import { reportCitrusImpression } from '@/features/citrus/utils/reportCitrusImpression';
import { formatPrice } from '../../../utils/validator/formatPrice';
type Props = {
  part: {
    properties: {
      seoUrl: string;
      imageUrl: string;
      refPageType: string;
      makeModelYearPath: string | undefined;
    };
    label: string;
    rating?: string;
    totalReviews?: string;
    averageRating?: string;
    citrusId?: string;
  };
  actionHandler: (b: string, a: string) => void;
  pricingInfo?: CertonaPricing | null;
  addToCartData?: AddToCartData | null;
  itemId?: string;
  isCertonaCard?: boolean;
  isFromExternal?: boolean;
  dynamicRef?: HTMLElement;
  onkeydown?: boolean;
  isCitrusCard?: boolean;
  showCard?: boolean;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  priorityImg?: boolean;
};

const labelMap = {
  priceUnavailable: 'label_ProductDisplayPage_body_PriceUnavailable',
  clickToSeePrice: 'label_ProductDisplayPage_body_ClickToSeePrice',
  lblCarouselImage: 'label_Carousel_Image',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblAddToCart: 'label_add_to_cart_certona',
};

function PartCard(props: Props) {
  const certonaAddToCartEnabled = useFeatureFlag('IS_CERTONA_ADD_TO_CART_LINK_ENABLED') === 'true';
  const { priceUnavailable, clickToSeePrice, lblAdd, lblToCart, lblAddToCart } =
    useLabels(labelMap);
  const router = useRouter();
  const { asPath } = router;
  const { data: storeDetailsData } = useStoreDetailsData();
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [showLoadingIndicators, setShowLoadingIndicators] = React.useState(false);
  const { mutate: addToCart } = useAddToCart({
    onAddToCartSuccess: () => {
      afterAddTocartSuccess();
    },
  });
  const {
    part: { properties, label, totalReviews, averageRating, citrusId },
    actionHandler,
    itemId = '',
    isCertonaCard,
    pricingInfo,
    isFromExternal,
    dynamicRef,
    onkeydown,
    isCitrusCard,
    addToCartAnalyticsandSuccessMessage,
    showCard = true,
    addToCartData,
    priorityImg = false,
  } = props;

  const { corePrice, retailPrice, totalPrice, configurableSku } = pricingInfo ?? {};

  const focusedElement = useActiveElement();
  const scrollIntoViewOptions: ScrollIntoViewOptions = {
    block: 'nearest',
    inline: 'nearest',
    behavior: 'auto',
  };

  React.useEffect(() => {
    if (onkeydown && focusedElement === dynamicRef) {
      focusedElement.scrollIntoView(scrollIntoViewOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedElement]);

  React.useEffect(() => {
    const currentCardRef = cardRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && citrusId) {
        void reportCitrusImpression(citrusId);
      }
    }, {});

    if (currentCardRef) {
      observer.observe(currentCardRef);
    }

    return () => {
      currentCardRef && observer.unobserve(currentCardRef);
    };
  }, [citrusId]);

  const handleClick = () => {
    !isCitrusCard && actionHandler(label, itemId);
    isCitrusCard && root.sessionStorage?.setItem('productType', 'sponsoredProduct');
    citrusId && reportCitrusClick(citrusId);
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.pageYOffset
    );
  };
  const afterAddTocartSuccess = () => {
    setShowLoadingIndicators(false);
    const addToCartAnalyticsData = {
      price: totalPrice?.toFixed(2),
      ...addToCartData,
    };
    addToCartAnalyticsandSuccessMessage &&
      addToCartAnalyticsandSuccessMessage(addToCartAnalyticsData);
  };

  const [isHovering, setIsHovering] = React.useState(false);
  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  if (!showCard) {
    return null;
  }

  const handleAddtoCartCtaClick = (event: any) => {
    event.stopPropagation();
    setShowLoadingIndicators(true);
    const addToCartRequest = {
      skuId: String(addToCartData?.skuId),
      productId: addToCartData?.productId,
      quantity: '1',
      fulfillmentTypeId: addToCartData?.fulFillmentId,
      vehicleId: addToCartData?.vehicleId,
      storeNumber: String(storeDetailsData?.storeNumber),
    };
    addToCart(addToCartRequest);
  };

  const addProductToCart = `${lblAdd} ${label} ${lblToCart}`;

  const formattedRetailPrice = formatPrice(retailPrice ?? 0);
  return (
    <Grid
      item
      lg={2}
      md={3}
      sm={6}
      className={cx(
        styles.part,
        azCommonStyles['az-padding-top-xxs'],
        azCommonStyles['az-padding-bottom-xxs'],
        isFromExternal && styles.partProductRecommendationCertona
      )}
      key={properties.seoUrl}
      ref={cardRef}
      isListItem
    >
      <Link
        to={properties.seoUrl}
        key={properties.seoUrl}
        onClick={handleClick}
        onAuxClick={(e) => {
          if (e.button === 1) {
            handleClick();
          }
        }}
        data-testid="part-link"
      >
        <Grid container>
          <Grid
            item
            className={isFromExternal ? styles.imageContainer : undefined}
            xs={isFromExternal ? 4 : 12}
          >
            <NextImage
              src={properties.imageUrl}
              alt=""
              className={cx({
                [styles.partImage]: !isCertonaCard,
                [styles.certonaPartImage]: isCertonaCard,
              })}
              data-testid="part-img"
              width={isCertonaCard ? 65 : 100}
              height={isCertonaCard ? 65 : 100}
              priority={priorityImg}
            />
          </Grid>
          <Grid
            item
            className={isFromExternal ? styles.contentContainer : undefined}
            xs={isFromExternal ? 8 : 12}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div
                  className={cx(
                    styles.partLabel,
                    {
                      [styles.certonaPartLabel]: isCertonaCard,
                    },
                    isFromExternal ? styles.partLabelRecommendation : undefined
                  )}
                  data-testid="part-label"
                >
                  {label}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                className={!isFromExternal ? styles.ratingsContainerPartCard : undefined}
              >
                {averageRating && Number(averageRating) > 0 && totalReviews ? (
                  <RatingsReview
                    averageRating={averageRating}
                    totalReviews={totalReviews}
                    showStarFractions
                  />
                ) : (
                  <div className={styles.emptyRatings} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  className={cx(
                    styles.pricingContainer,
                    isFromExternal ? styles.pricingContainerRecommendation : undefined
                  )}
                  alignItems="center"
                >
                  {(isCertonaCard &&
                    (totalPrice === null || totalPrice === undefined || isNaN(totalPrice))) ||
                  (!isCertonaCard && totalPrice === null) ? (
                    <span className={styles.pricingInfo}>{priceUnavailable}</span>
                  ) : null}

                  {!configurableSku && typeof totalPrice === 'number' ? (
                    <>
                      <Price
                        className={styles.price}
                        dealPrice={
                          corePrice
                            ? formatPrice(totalPrice)?.toString().split('.') ?? []
                            : formattedRetailPrice?.toString().split('.') ?? []
                        }
                        priceType="secondary"
                      />
                      {totalPrice !== undefined && retailPrice !== totalPrice && !corePrice && (
                        <div className={cx(azCommonStyles['az-caption'], styles.totalPrice)}>
                          {`$${formatPrice(totalPrice)}`}
                        </div>
                      )}
                    </>
                  ) : null}

                  {configurableSku && totalPrice !== null ? (
                    <span className={styles.pricingInfo}>{clickToSeePrice}</span>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Link>
      {asPath === routePaths.cart && certonaAddToCartEnabled && !isCitrusCard && (
        <Grid
          xs={10}
          lg={14}
          className={styles.addtoCartContainer}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {!showLoadingIndicators && isHovering && (
            <Button
              variant="link"
              ariaLabel={addProductToCart}
              customClass={styles.addToCartHoverCta}
              onClick={(e) => handleAddtoCartCtaClick(e)}
            >
              <span className={cx(styles.plusCircleImg, styles.shapePlusCircle)}>
                <Image
                  src={'/images/shape-plus-circle-hover.svg'}
                  alt={''}
                  width={15}
                  height={15}
                  loading="eager"
                />
              </span>{' '}
              {lblAddToCart}
            </Button>
          )}
          {!showLoadingIndicators && !isHovering && (
            <Button
              variant="link"
              ariaLabel={addProductToCart}
              customClass={styles.addtoCartCta}
              onClick={(e) => handleAddtoCartCtaClick(e)}
            >
              <span className={cx(styles.plusCircleImg, styles.shapePlusCircle)}>
                <Image src={'/images/shape_plus_circle.svg'} alt={''} width={15} height={15} />
              </span>
              {lblAddToCart}
            </Button>
          )}
          {showLoadingIndicators && (
            <div className={styles.loadingIndicator}>
              <LoadingIndicatorDots size={12} color="#202124" />
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default PartCard;
