/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Star } from './Star';
import styles from './Rating.module.scss';

function getDecimalPrecision(num: number) {
  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function roundValueToPrecision(value: number, precision: number) {
  if (value == null) {
    return value;
  }

  const nearest = Math.round(value / precision) * precision;
  return Number(nearest.toFixed(getDecimalPrecision(precision)));
}

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}`;
}

type Props = {
  /**
   * The rating value.
   */
  value: number;
  max?: number;
  ariaLabel?: string;
  showStarFractions?: boolean;
  isOosOrDoesNotFit?: boolean;
  isCertonaPartCard?: boolean;
};
const precision = 0.1;
const maxValue = 5;

function Rating({
  value: valueProp,
  max,
  ariaLabel,
  showStarFractions = false,
  isOosOrDoesNotFit,
  isCertonaPartCard,
}: Props) {
  const value = roundValueToPrecision(valueProp, precision);
  return (
    <span
      className={styles.root}
      role={'img'}
      aria-label={ariaLabel ? ariaLabel : getLabelText(value)}
    >
      {Array.from(new Array(max ?? maxValue)).map((_, index) => {
        const itemValue = index + 1;
        const itemDecimalValue = roundValueToPrecision(itemValue, precision);
        const fraction = showStarFractions
          ? 1 - Math.max(0, itemDecimalValue - value)
          : itemDecimalValue <= value
          ? 1
          : 0;
        return (
          <span key={itemValue} className={styles.icon} aria-hidden="true">
            <Star
              percent={roundValueToPrecision(fraction * 100, 0.01)}
              isOosOrDoesNotFit={isOosOrDoesNotFit}
              isCertonaPartCard={isCertonaPartCard}
            />
          </span>
        );
      })}
    </span>
  );
}

export { Rating };
