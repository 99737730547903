/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Input } from '../Input/Input';
import type { Props as InputProps } from '../Input/Input';
import ArrowDropDownIcon from '../../../public/images/arrow-drop-down.svg';
import iconStyles from '../../theme/iconStyles.module.scss';
import { NativeSelectInput } from './NativeSelect/NativeSelectInput';
type Props = {
  /**
   * The option elements to populate the select with.
   * Can be some `MenuItem` when `native` is false and `option` when `native` is true.
   *
   * ⚠️The `MenuItem` elements **must** be direct descendants when `native` is false.
   */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes?: {
    select?: string;
  };
  /**
   * The icon that displays the arrow.
   */
  IconComponent?: React.ComponentType<any>;
  /**
   * An `Input` element; does not have to be a material-ui specific `Input`.
   */
  input?: React.ReactElement<any>;
  /**
   * If `true`, `value` must be an array and the menu will support multiple selections.
   */
  multiple?: boolean;
} & InputProps;

export const Select = React.forwardRef<HTMLElement, Props>(function Select(props, ref) {
  const {
    children,
    classes,
    IconComponent = ({ className, ...props }) => (
      <ArrowDropDownIcon
        aria-hidden
        className={cx(iconStyles.defaultIconStyle, className)}
        {...props}
      />
    ),
    id,
    input,
    name,
    multiple = false,
    inputProps,
    ...other
  } = props;

  const InputComponent = input || <Input />;
  return React.cloneElement(InputComponent, {
    // Most of the logic is implemented in `SelectInput`.
    // The `Select` component is a simple API wrapper to expose something better to play with.
    inputComponent: NativeSelectInput,
    inputProps: {
      children,
      IconComponent,
      type: undefined, // We render a select. We can ignore the type provided by the `Input`.
      multiple,
      id,
      name,
      classes,
      ...(input ? input.props.inputProps : inputProps ? inputProps : {}),
    },
    ref,
    ...other,
  });
});

// @ts-expect-error for input adornment
Select.muiName = 'Select';
