/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { MutableRefObject, RefObject, useEffect, useRef, useState, createRef } from 'react';
import root from 'window-or-global';
import type { AddCertonaItemObjectType } from '../../interface';
import Image from '@/components/NextImage';
import type { AddToCartData, CertonaItem, CertonaPricing } from '@/types';
import styles from './styles.module.scss';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { HorizontalPartCard } from '../HorizontalPartCard';
import { LargePartCard } from '../LargePartCard';
import { FULFILLMENT_METHODS } from '../../../../constants/fulfillmentConstants';
import { certonaPageType } from '@/constants/certonaPageType';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import type { SkuAvailabilty } from '@/types/availability';
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import Hidden from '@/components/Hidden';
import { useFeatureFlag } from '@/features/globalConfig';
import { routePaths } from '@/constants/routePaths';

type Props = {
  id?: string | undefined;
  title: string;
  subtitle: string;
  partsList: CertonaItem[];
  pricingInfo?: Record<string, CertonaPricing>;
  pageType: '' | string;
  contentsAligment?: 'vertical' | 'horizontal';
  onCertonaRecommendationClick?: () => void;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  updateAfterAddtoCartClick?: (item?: AddToCartData) => void;
  skuDetailsData?: SkuAvailabilty[] | null;
  thresholdHeaderText?: string;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  isOos?: boolean;
  doesNotFit?: boolean;
  shelfSeoUrl?: string;
  inDrawer?: boolean;
};

const labelMap = {
  lblDelivery: 'label_threshold_delivery',
  lblSpend: 'label_threshold_spend',
  lblThresholdContainerHeader: 'label_ThresholdContainerHeader',
};

const ScrolledPartCardContainer = (props: Props) => {
  const {
    id,
    title,
    subtitle,
    partsList,
    pricingInfo,
    contentsAligment = 'vertical',
    skuDetailsData,
    addToCartAnalyticsandSuccessMessage,
    onCertonaRecommendationClick,
    pageType,
    thresholdHeaderText = '',
    isOos,
    doesNotFit,
    shelfSeoUrl,
    setAddedCertonaItemObject,
    inDrawer,
    updateAfterAddtoCartClick,
  } = props;
  const isOosOrDoesNotFit = isOos || doesNotFit;
  const { isUtagReady: isUtagDefined } = useUtagReady();
  const { lblThresholdContainerHeader } = useLabels(labelMap);
  const router = useRouter();
  const isPDP = router.pathname === '/products/[...slug]';
  const isHome = router.pathname === '/';
  const isShelf = router.pathname === '/[...seoUrlPath]';
  const isCart = router.pathname === '/cart';
  const isVehicleDetailsPage = router.pathname.includes('vehicleDetails');
  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );
  const isSearchPage = router.pathname.includes('search');
  const [selectedPartCardIndex, setSelectedPartCardIndex] = useState(0);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const partListRefs = useRef<Array<MutableRefObject<HTMLLIElement>>>([]);
  const unorderedListElement: RefObject<HTMLUListElement> = useRef<HTMLUListElement>(null);
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isHomePageCertonaAddToCartEnabled =
    useFeatureFlag('IS_HOME_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isShelfPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isSearchPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SEARCH_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const showAddtoCartCTA =
    isCart ||
    isVehicleDetailsPage ||
    isRecommendationsLandingPage ||
    (isPDP && isPdpCertonaAddToCartEnabled) ||
    (isHome && isHomePageCertonaAddToCartEnabled) ||
    (isShelf && isShelfPageCertonaAddToCartEnabled) ||
    (isSearchPage && isSearchPageCertonaAddToCartEnabled);
  const preferredVehicleId = usePreferredVehicle();
  const vehicleName = !!preferredVehicleId ? preferredVehicleId.make : 'Vehicle';
  const isDefaultTitle = subtitle === '' && title !== '';

  const trackCertonaLinkInteractionBySection = (linkName: string, section: string, id: string) => {
    // TODO - Figure out lint error to and remove eslint comments
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const utag = root.utag_data;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const sectionName = `${section ?? utag?.pageType}_certona`;
    const data = {
      productLinkName: linkName,
      clickedProductId: id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      pageName: utag?.pageName ?? '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      pageType: utag?.pageType ?? '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      siteSection: utag?.siteSection ?? '',
      eventType: sectionName.replace(/\s/g, '_').toLowerCase(),
    };
    clickTrack(data);
  };

  const handleCertonaClick = (linkName: string, id: string) => {
    onCertonaRecommendationClick?.();
    root.sessionStorage.setItem('productType', 'certona');

    if (isUtagDefined === undefined && pageType !== certonaPageType.shoppingCartPage) {
      trackCertonaLinkInteractionBySection(linkName, pageType, id);
    }
  };

  useEffect(() => {
    const scrollBlockScrolled = () => {
      if (unorderedListElement.current && partListRefs.current[partListRefs.current.length - 1]) {
        if (
          partListRefs.current[partListRefs.current.length - 1]?.current?.getBoundingClientRect()
            .right -
            1 >
          unorderedListElement.current.getBoundingClientRect().right
        ) {
          setHideRightArrow(false);
        } else {
          setHideRightArrow(true);
        }
      }

      if (unorderedListElement.current && partListRefs.current[0]) {
        if (
          partListRefs.current[0]?.current?.getBoundingClientRect().left <
          unorderedListElement.current.getBoundingClientRect().left
        ) {
          setHideLeftArrow(false);
        } else {
          setHideLeftArrow(true);
        }
      }
    };

    let ulElementRefValue: HTMLUListElement | null = null;

    if (unorderedListElement.current) {
      ulElementRefValue = unorderedListElement.current;
      unorderedListElement.current.addEventListener('scroll', scrollBlockScrolled);
      window.addEventListener('resize', scrollBlockScrolled);
    }

    scrollBlockScrolled();

    return () => {
      if (ulElementRefValue) {
        ulElementRefValue.removeEventListener('scroll', scrollBlockScrolled);
        window.removeEventListener('resize', scrollBlockScrolled);
      }
    };
  }, [partsList]);

  const handleLeftClick = () => {
    if (
      partListRefs.current.length &&
      unorderedListElement.current &&
      partListRefs.current[0]?.current?.getBoundingClientRect()?.left <
        unorderedListElement.current.getBoundingClientRect().left
    ) {
      const partCards = partListRefs.current;
      const targetPartCard = partCards[selectedPartCardIndex > 0 ? selectedPartCardIndex - 1 : 0];

      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedPartCardIndex(selectedPartCardIndex > 0 ? selectedPartCardIndex - 1 : 0);
    }
  };

  const handleRightClick = () => {
    if (
      partListRefs.current.length &&
      unorderedListElement.current &&
      partListRefs.current[partListRefs.current.length - 1].current.getBoundingClientRect().right >
        unorderedListElement.current.getBoundingClientRect().right
    ) {
      const partCards = partListRefs.current;
      const targetPartCard =
        partCards[
          selectedPartCardIndex < partListRefs.current.length - 1
            ? selectedPartCardIndex + 1
            : partListRefs.current.length - 1
        ];
      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedPartCardIndex(
        selectedPartCardIndex < partListRefs.current.length - 1
          ? selectedPartCardIndex + 1
          : partListRefs.current.length - 1
      );
    }
  };

  if (partsList.length && partListRefs.current.length !== partsList.length) {
    partListRefs.current = Array(partsList.length)
      .fill(null)
      .map((_, i) => partListRefs.current[i] || createRef());
  }
  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleRightClick(),
    onSwipedRight: () => handleLeftClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const newTitle =
    !!title && title.toUpperCase().includes('RECOMMENDED FOR YOU') && !!preferredVehicleId
      ? `Recommended For Your ${vehicleName}`
      : title;

  return partsList.length > 0 ? (
    <div
      {...swipeableHandlers}
      data-testid="carousel"
      className={cx(
        isPDP && isOosOrDoesNotFit && !(contentsAligment === 'vertical')
          ? styles.swipeableContainerForOos
          : styles.swipeableContainer,
        {
          [styles.thresholdContainer]: id === 'certona-threshold',
        }
      )}
    >
      {!subtitle && !!title && (
        <>
          <h2
            className={cx(styles.partTitle, azCommonStyles['az-padding-bottom-4xs'])}
            data-testid="dynamic-content"
          >
            {newTitle}
          </h2>
          <div className={styles.separator} />
        </>
      )}

      {!!subtitle && <h2 className={styles.thresholdHeaderText}>{subtitle}</h2>}

      {!!thresholdHeaderText && (
        <>
          <h2
            className={styles.thresholdHeaderText}
            data-testid="dynamic-content"
            id="shipping-threshold-banner"
          >
            {`${lblThresholdContainerHeader}`}
          </h2>
        </>
      )}

      <div
        className={cx(
          styles.scrollingContainer,
          contentsAligment === 'vertical'
            ? styles.verticalScrollBlock
            : styles.horizontalScrollBlock,
          {
            [styles.marginForThresholdHeader]: !!thresholdHeaderText,
            [styles.oosNoMargin]: isPDP && isOosOrDoesNotFit && !(contentsAligment === 'vertical'),
            [styles.scrollingContainerOverflow]:
              isPDP && isOosOrDoesNotFit && !(contentsAligment === 'vertical'),
          }
        )}
      >
        <Hidden smDown>
          <div
            role="button"
            data-testid="carousel-left-arrow"
            className={cx(
              styles.leftArrow,
              {
                [styles.hiddenArrow]: hideLeftArrow,
                [styles.arrowAlignement]: contentsAligment !== 'vertical' && !isOosOrDoesNotFit,
                [styles.oosArrowAlignment]: contentsAligment !== 'vertical' && isOosOrDoesNotFit,
              },
              contentsAligment === 'vertical'
                ? styles.leftArrowVertical
                : styles.leftArrowHorizontal
            )}
            tabIndex={0}
            onClick={handleLeftClick}
            onKeyDown={(e) => {
              if ((e || window.event).keyCode == 13) {
                handleLeftClick();
              }
            }}
          >
            <Image
              className={styles.chevronImg}
              src="/images/arrow_right_initial.svg"
              alt="scroll carousel left"
              height={64}
              width={57}
            />
            <Image
              className={styles.chevronImgHover}
              src="/images/arrow_right_hover.svg"
              alt=""
              height={68}
              width={57}
            />
          </div>
        </Hidden>
        <ul
          className={cx(styles.cardsList, {
            [styles.oosCardList]: isPDP && isOosOrDoesNotFit && !(contentsAligment === 'vertical'),
            [styles.cartCardList]: isCart,
            [styles.increasedPadding]: isDefaultTitle,
          })}
          ref={unorderedListElement}
        >
          {partsList.map(
            (
              {
                id,
                product_image_url,
                product_url_relative,
                product_name,
                total_review_count,
                average_rating,
                rating,
              },
              index
            ) => {
              const skuDetails = skuDetailsData?.find((data: SkuAvailabilty) => {
                return data.skuId === id;
              });

              const bopusQuantity = skuDetails?.availabilityInfoVO.bopusQuantity;
              const sthQuantity = skuDetails?.availabilityInfoVO.sthQuantity;
              const nddQuantity = skuDetails?.availabilityInfoVO.nddQuantity;

              const fulFillmentId =
                !!bopusQuantity && bopusQuantity > 0
                  ? FULFILLMENT_METHODS.STOREORDER
                  : !!sthQuantity && sthQuantity > 0
                  ? FULFILLMENT_METHODS.ONLINEORDER
                  : null;

              const onlineOrderFulfillmentId =
                nddQuantity && nddQuantity > 0
                  ? FULFILLMENT_METHODS.NEXTDAY
                  : FULFILLMENT_METHODS.ONLINEORDER;

              const addToCartInfo = {
                skuId: skuDetails?.skuId,
                productId: skuDetails?.attributesInfoVO.productId,
                vehicleId: skuDetails?.catalogVehicleId ? skuDetails.catalogVehicleId : '',
                fulFillmentId: thresholdHeaderText ? onlineOrderFulfillmentId : fulFillmentId,
                brandName: skuDetails?.attributesInfoVO.brandName,
                originalPartTypeId: skuDetails?.attributesInfoVO.originalPartTypeId ?? 0,
                productPartNumber: skuDetails?.attributesInfoVO.productPartNumber,
                productRepositoryId: skuDetails?.attributesInfoVO.productRepositoryId,
              };

              return (
                <li
                  key={id}
                  className={
                    isPDP || isHome || isShelf || isSearchPage
                      ? styles.newCardItem
                      : styles.cardItem
                  }
                  ref={partListRefs.current[index]}
                >
                  {contentsAligment === 'vertical' ? (
                    <LargePartCard
                      key={id}
                      part={{
                        properties: {
                          seoUrl: product_url_relative,
                          imageUrl: product_image_url,
                          makeModelYearPath: undefined,
                          refPageType: '',
                        },
                        label: product_name,
                        rating,
                        totalReviews: total_review_count,
                        averageRating: average_rating,
                      }}
                      pricingInfo={pricingInfo?.[id]}
                      addToCartData={skuDetails ? addToCartInfo : {}}
                      actionHandler={handleCertonaClick}
                      addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                      setAddedCertonaItemObject={setAddedCertonaItemObject}
                      showAddtoCartCTA={showAddtoCartCTA}
                    />
                  ) : (
                    <HorizontalPartCard
                      key={id}
                      part={{
                        properties: {
                          seoUrl: product_url_relative,
                          imageUrl: product_image_url,
                          makeModelYearPath: undefined,
                          refPageType: '',
                        },
                        label: product_name,
                        rating,
                        totalReviews: total_review_count,
                        averageRating: average_rating,
                      }}
                      pricingInfo={pricingInfo?.[id]}
                      addToCartData={skuDetails ? addToCartInfo : {}}
                      updateAfterAddtoCartClick={updateAfterAddtoCartClick}
                      actionHandler={handleCertonaClick}
                      addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                      showAddtoCartCTA={showAddtoCartCTA}
                      isOos={isOos}
                      doesNotFit={doesNotFit}
                      lastIndex={index === partsList.length - 1}
                      partsListLength={partsList.length}
                      shelfSeoUrl={shelfSeoUrl}
                      inDrawer={inDrawer}
                      setAddedCertonaItemObject={setAddedCertonaItemObject}
                      cardStyle={'horizontal_scrolled'}
                    />
                  )}
                </li>
              );
            }
          )}
        </ul>
        <Hidden smDown>
          <div
            role="button"
            data-testid="carousel-right-arrow"
            className={cx(styles.rightArrow, {
              [styles.hiddenArrow]: hideRightArrow,
              [styles.arrowAlignement]: contentsAligment !== 'vertical' && !isOosOrDoesNotFit,
              [styles.oosArrowAlignment]: contentsAligment !== 'vertical' && isOosOrDoesNotFit,
            })}
            tabIndex={0}
            onClick={handleRightClick}
            onKeyDown={(e) => {
              if ((e || window.event).keyCode == 13) {
                handleRightClick();
              }
            }}
          >
            <Image
              className={styles.chevronImg}
              src="/images/arrow_right_initial.svg"
              alt="scroll carousel right"
              height={64}
              width={57}
            />
            <Image
              className={styles.chevronImgHover}
              src="/images/arrow_right_hover.svg"
              alt=""
              height={68}
              width={57}
            />
          </div>
        </Hidden>
      </div>
    </div>
  ) : null;
};

export default ScrolledPartCardContainer;
