/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useReducer, useEffect, useState, useRef, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import cx from 'classnames';
import root from 'window-or-global';
import { certonaPageType } from '@/constants/certonaPageType';
import PartCard from '../PartCard';
import Image from '@/components/NextImage';
import azCommonStyles from '../../../theme/globals.module.scss';
import ContentContainer from '../ContentContainer';
import { defaultStoreUS } from '@/constants/locale';
import type { CertonaItem, AddToCartData } from '@/types';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useRouter } from 'next/router';
import { FULFILLMENT_METHODS } from '../../../constants/fulfillmentConstants';
import { routePaths } from '../../../constants/routePaths';
import { useFeatureFlag } from '@/features/globalConfig';
import type { SkuAvailabilty } from '@/types/availability';
import { useGetProductSkuPrice } from '@/features/product/api/getProductSkuPrice';

type Props = {
  title: string;
  partsList: CertonaItem[];
  pageType: '' | string;
  greyBackground?: boolean;
  isFromExternal?: boolean;
  isCitrusCard?: boolean;
  onCertonaRecommendationClick?: () => void;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  skuDetailsData?: SkuAvailabilty[] | null;
  cardsContainerCustomClass?: string;
  carouselId?: string;
};

type State = {
  componentListOfParts: any[];
};

const initialState: State = {
  componentListOfParts: [],
};

const carouselReducer = (state: State, action: { type: string; payload: any[] }) => {
  switch (action.type) {
    case 'setInitialList':
      return { ...state, componentListOfParts: action.payload };
    case 'leftClick':
      return { ...state, componentListOfParts: action.payload };
    case 'rightClick':
      return { ...state, componentListOfParts: action.payload };
    default:
      return state;
  }
};

const trackCertonaLinkInteractionBySection = (linkName: string, section: string, id: string) => {
  const utag = root.utag_data;
  const sectionName = `${section ?? utag?.pageType}_certona`;
  const data = {
    productLinkName: linkName,
    clickedProductId: id,
    pageName: utag?.pageName ?? '',
    pageType: utag?.pageType ?? '',
    siteSection: utag?.siteSection ?? '',
    eventType: sectionName.replace(/\s/g, '_').toLowerCase(),
  };
  clickTrack(data);
};

function PartsCarousel(props: Props) {
  const {
    title,
    partsList,
    pageType,
    isFromExternal,
    isCitrusCard = false,
    onCertonaRecommendationClick,
    addToCartAnalyticsandSuccessMessage,
    skuDetailsData,
    cardsContainerCustomClass,
    carouselId,
  } = props;
  const { isUtagReady: isUtagDefined } = useUtagReady();
  const certonaAddToCartEnabled = useFeatureFlag('IS_CERTONA_ADD_TO_CART_LINK_ENABLED') === 'true';
  const [{ componentListOfParts }, dispatch] = useReducer(carouselReducer, initialState);
  const { data: headerData } = useHeaderData();
  const storeNumber = headerData?.storeNumber || defaultStoreUS;
  const router = useRouter();
  const { asPath } = router;

  const carouselRef = useRef<null | any>(null);

  useEffect(() => {
    dispatch({ type: 'setInitialList', payload: partsList });
  }, [partsList]);

  const skuNumbers = partsList.map((part) => part.id);

  const { data: pricingInfo } = useGetProductSkuPrice({
    skuNumbers,
    storeNumber,
    enabled: !!storeNumber,
    staleTime: 5 * 60000, // 5 minutes
  });

  const [keydown, setKeydown] = useState(false);

  const handlekeyPress = useCallback((event: MouseEvent | KeyboardEvent) => {
    if ('keyCode' in event && event?.keyCode === 9) {
      setKeydown(true);
    } else {
      setKeydown(false);
    }
  }, []);

  useEffect(() => {
    root?.document?.addEventListener('keydown', handlekeyPress);
    root?.document?.addEventListener('mousedown', handlekeyPress);
    return () => {
      root?.document?.removeEventListener('keydown', handlekeyPress);
      root?.document?.removeEventListener('mousedown', handlekeyPress);
    };
  }, [handlekeyPress]);

  const handleCertonaClick = (linkName: string, id: string) => {
    onCertonaRecommendationClick?.();
    root.sessionStorage?.setItem('productType', 'certona');

    if (isUtagDefined === undefined && pageType !== certonaPageType.shoppingCartPage) {
      trackCertonaLinkInteractionBySection(linkName, pageType, id);
    }
  };

  const handleRightClick = () => {
    const newListOfParts: any[] = [];
    componentListOfParts.forEach((_, index) => {
      if (index === 0) {
        newListOfParts.push(componentListOfParts[componentListOfParts.length - 1]);
      } else {
        newListOfParts.push(componentListOfParts[index - 1]);
      }
    });
    dispatch({ type: 'rightClick', payload: newListOfParts });
  };

  const handleLeftClick = () => {
    const newListOfParts = [];
    componentListOfParts.forEach((_, index) => {
      if (index !== 0) {
        newListOfParts.push(componentListOfParts[index]);
      }
    });
    newListOfParts.push(componentListOfParts[0]);
    dispatch({ type: 'leftClick', payload: newListOfParts });
  };

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleLeftClick(),
    onSwipedRight: () => handleRightClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...swipeableHandlers} data-testid="carousel" className={styles.swipeableContainer}>
      <ContentContainer
        type="simple"
        withMargin
        className={`${styles.carousel} ${props.greyBackground ? styles.greyBackground : ''} ${
          !isCitrusCard ? styles.miniCartMargin : ''
        }
        `}
      >
        <h2
          className={cx(
            styles.partTitle,
            !isFromExternal && azCommonStyles['az-padding-bottom-4xs'],
            isFromExternal && styles.partTitleProductRecommendationCertona
          )}
          data-testid="dynamic-content"
        >
          {title}
        </h2>
        {!isFromExternal && <div className={styles.separator} />}
        <div className={cx(styles.list_parts, isFromExternal ? styles.certonaCarousel : undefined)}>
          <div
            data-testid="carousel-left-arrow"
            className={cx(
              styles.leftArrow,
              isFromExternal ? styles.leftArrowRecommendation : undefined
            )}
            onClick={handleLeftClick}
            onKeyDown={(e) => {
              if ((e || window.event).keyCode == 13) {
                handleLeftClick();
              }
            }}
            tabIndex={0}
          >
            <Image
              className={styles.chevron}
              src="/images/left-arrow-grey.svg"
              alt="scroll carousel left"
              height={25}
              width={15}
            />
          </div>
          <ul
            ref={carouselRef}
            {...(carouselId && { id: carouselId })}
            className={cx(styles.cards, cardsContainerCustomClass)}
          >
            {componentListOfParts?.map(
              (
                {
                  id,
                  product_url_relative,
                  product_image_url,
                  product_name,
                  rating,
                  total_review_count,
                  average_rating,
                  isQuotientCard,
                  citrusId,
                },
                idx
              ) => {
                const dynamicRef = carouselRef.current?.children[idx]?.children[0];
                const skuDetails = skuDetailsData?.find((data: SkuAvailabilty) => {
                  return data?.skuId === id;
                });
                const bopusQuantity = skuDetails?.availabilityInfoVO?.bopusQuantity;
                const sthQuantity = skuDetails?.availabilityInfoVO?.sthQuantity;

                const fulFillmentId =
                  !!bopusQuantity && bopusQuantity > 0
                    ? FULFILLMENT_METHODS.STOREORDER
                    : !!sthQuantity && sthQuantity > 0
                    ? FULFILLMENT_METHODS.ONLINEORDER
                    : null;

                const addToCartInfo = {
                  skuId: skuDetails?.skuId,
                  productId: skuDetails?.attributesInfoVO.productId,
                  vehicleId: skuDetails?.catalogVehicleId ? skuDetails.catalogVehicleId : '',
                  fulFillmentId: fulFillmentId,
                  brandName: skuDetails?.attributesInfoVO.brandName,
                };
                const showCard =
                  asPath === routePaths.cart &&
                  !isQuotientCard &&
                  certonaAddToCartEnabled &&
                  !isCitrusCard
                    ? (!!bopusQuantity && bopusQuantity > 0 && !!pricingInfo?.[id]?.totalPrice) ||
                      (!!sthQuantity && sthQuantity > 0 && !!pricingInfo?.[id]?.totalPrice)
                    : true;

                return (
                  <PartCard
                    key={id}
                    dynamicRef={dynamicRef as HTMLElement}
                    part={{
                      properties: {
                        seoUrl: product_url_relative,
                        imageUrl: product_image_url,
                        refPageType: '',
                        makeModelYearPath: undefined,
                      },
                      label: product_name,
                      rating,
                      totalReviews: total_review_count,
                      averageRating: average_rating,
                      citrusId,
                    }}
                    pricingInfo={pricingInfo?.[id]}
                    addToCartData={
                      asPath === routePaths.cart && certonaAddToCartEnabled ? addToCartInfo : null
                    }
                    showCard={showCard}
                    actionHandler={handleCertonaClick}
                    isCertonaCard
                    isCitrusCard={isCitrusCard}
                    isFromExternal={isFromExternal}
                    onkeydown={keydown}
                    addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                  />
                );
              }
            )}
          </ul>
          <div
            data-testid="carousel-right-arrow"
            className={`${styles.rightArrow} ${
              props.greyBackground ? styles.greyBgrightArrow : ''
            }`}
            onClick={handleRightClick}
            onKeyDown={(e) => {
              if ((e || window.event).keyCode == 13) {
                handleRightClick();
              }
            }}
          >
            <div tabIndex={0}>
              <Image
                className={styles.chevron}
                src="/images/left-arrow-grey.svg"
                alt="scroll carousel right"
                height={25}
                width={15}
              />
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}

export default PartsCarousel;
