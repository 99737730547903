/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLocale } from '@/hooks/useLocale';
import { useMemo } from 'react';

export const useFormatCompactNumber = (value: number | string) => {
  const locale = useLocale();
  const numValue = typeof value === 'string' ? Number(value) : value;
  const formatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      notation: 'compact',
    });
  }, [locale]);

  return formatter.format(numValue);
};
