/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import logger from '@/utils/logger';
import { sessionStorage } from '@/utils/sessionStorage';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';

export const reportCitrusImpression = async (id: string) => {
  const currentImpressions = sessionStorage.getItem('citrusImpressions') ?? '';
  if (!currentImpressions.includes(id)) {
    try {
      const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;
      const { baseURL, axiosConfig } = getCitrusProxyConfiguration();
      const axios = getAxiosForClient();
      !CITRUS_PROXY_DISABLED
        ? await axios.get(`${baseURL}/v1/resource/first-i/${id}`, axiosConfig)
        : await axios.post('/api/citrus/impression', {
            id,
          });
      sessionStorage.setItem(`citrusImpressions`, `${currentImpressions} ${id}`);
    } catch (error) {
      logger.error({
        message: 'Error while reporting Citrus impression (FE)',
        meta: {
          error,
        },
      });
    }
  }
};
