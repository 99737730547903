/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';
import type { CertonaRecommendation, CertonaOptions } from '../interface';
import { getCertonaOptions } from '../utils/getCertonaOptions';
import { useRouter } from 'next/router';
import { useDeviceTypeByMediaQuery } from '@/hooks/useDeviceTypeByMediaQuery';

/**
 * This hook returns the array of recommendations made by the CERTONA external service depending on
 * the certona page type [there is a constant file where all the page types are "src/constants/certonaPageType.js"]
 * when you are using the hook pass the correct keys depending on the page type, in this link you can see a PDF
 * describing all the possibilities https://track.autozone.com/secure/attachment/55544/Certona-AutoZone-REACT-Implementation-Instructions-v1.3_021120%20%282%29.pdf
 * @param {Object} certonaOptions Object with different keys depending on the certona page type
 */

export const useGetCertonaRecommendations = (
  certonaOptions: CertonaOptions,
  /*
dependecies: the dependency array used in the custom hook. Having no dependencies results in the hook
only firing once and only once upon mount, a behavior similar to onComponentMount().
*/
  dependencies: unknown[] = [],
  /*
shouldExecuteCall: flag for calling Certona's 3rd party api to fetch recommendation data. Should only be needed
for pages where Certona recommendation pages are displayed.
*/
  shouldExecuteCall = true
): CertonaRecommendation[] => {
  const deviceType = useDeviceTypeByMediaQuery();
  const router = useRouter();
  const [certonaRecommendations, setCertonaRecommendations] = useState([]);

  useEffect(() => {
    return () => {
      setCertonaRecommendations([]);
    };
  }, [router.asPath]);

  useEffect(() => {
    const configuredCertonaOptions = getCertonaOptions(certonaOptions);

    const fetchRecommendationData = async () => {
      if (typeof window !== 'undefined') {
        if (typeof window.certonaNotCalled === 'undefined') {
          window.certonaNotCalled = true;
        }

        const certonaObject = {
          device: deviceType,
          recommendations: true,
          ...configuredCertonaOptions,
        };
        window.certona = certonaObject;

        if (window.callCertona && window.certonaNotCalled) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await window.callCertona();
        }

        window.certonaRecommendations = (response: unknown) => {
          // @ts-expect-error improve typesafety
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          setCertonaRecommendations(response.resonance.schemes);
          window.certonaNotCalled = false;
        };
      }
    };

    shouldExecuteCall ? void fetchRecommendationData() : null;

    return () => {
      typeof window !== 'undefined' ? (window.certonaNotCalled = undefined) : undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    return () => setCertonaRecommendations([]);
  }, []);
  return certonaRecommendations;
};
