/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';
import type { AddCertonaItemObjectType } from '../../interface';
import type { AddToCartData, CertonaItem, CertonaPricing } from '@/types';
import styles from './styles.module.scss';
import type { SkuAvailabilty } from '@/types/availability';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { HorizontalPartCard } from '../HorizontalPartCard';
import { LargePartCard } from '../LargePartCard';
import { FULFILLMENT_METHODS } from '../../../../constants/fulfillmentConstants';
import { certonaPageType } from '@/constants/certonaPageType';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useFeatureFlag } from '@/features/globalConfig';

type Props = {
  title: string;
  partsList: CertonaItem[];
  pricingInfo?: Record<string, CertonaPricing>;
  pageType: '' | string;
  contentsAligment?: 'vertical' | 'horizontal';
  greyBackground?: boolean;
  isFromExternal?: boolean;
  isQuotientCard?: boolean;
  onCertonaRecommendationClick?: () => void;
  updateAfterAddtoCartClick?: (item?: AddToCartData) => void;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  skuDetailsData?: SkuAvailabilty[] | null;
  thresholdHeaderText?: string;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  inDrawer: boolean;
};
const labelMap = {
  lblDelivery: 'label_threshold_delivery',
  lblSpend: 'label_threshold_spend',
  lblThresholdContainerHeader: 'label_ThresholdContainerHeader',
};

const StackedPartCardContainer = (props: Props) => {
  const {
    title,
    partsList,
    pricingInfo,
    contentsAligment = 'vertical',
    skuDetailsData,
    addToCartAnalyticsandSuccessMessage,
    onCertonaRecommendationClick,
    updateAfterAddtoCartClick,
    pageType,
    thresholdHeaderText,
    setAddedCertonaItemObject,
    inDrawer,
  } = props;

  const { isUtagReady: isUtagDefined } = useUtagReady();

  const { lblThresholdContainerHeader } = useLabels(labelMap);
  const router = useRouter();
  const isPDP = router.pathname === '/products/[...slug]';
  const isHome = router.pathname === '/';
  const isShelf = router.pathname === '/[...seoUrlPath]';
  const isCart = router.pathname === '/cart';
  const isSearchPage = router.pathname.includes('search');
  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isHomePageCertonaAddToCartEnabled =
    useFeatureFlag('IS_HOME_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isShelfPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isSearchPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SEARCH_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const showAddtoCartCTA =
    inDrawer ||
    isCart ||
    isRecommendationsLandingPage ||
    (isPDP && isPdpCertonaAddToCartEnabled) ||
    (isHome && isHomePageCertonaAddToCartEnabled) ||
    (isShelf && isShelfPageCertonaAddToCartEnabled) ||
    (isSearchPage && isSearchPageCertonaAddToCartEnabled);
  const trackCertonaLinkInteractionBySection = (linkName: string, section: string, id: string) => {
    const utag = root.utag_data;
    const sectionName = `${section ?? utag?.pageType}_certona`;
    const data = {
      productLinkName: linkName,
      clickedProductId: id,
      pageName: root.utag_data?.pageName ?? '',
      pageType: root.utag_data?.pageType ?? '',
      siteSection: root.utag_data?.siteSection ?? '',
      eventType: sectionName.replace(/\s/g, '_').toLowerCase(),
    };
    clickTrack(data);
  };

  const handleCertonaClick = (linkName: string, id: string) => {
    onCertonaRecommendationClick?.();
    root.sessionStorage.setItem('productType', 'certona');

    if (isUtagDefined === undefined && pageType !== certonaPageType.shoppingCartPage) {
      trackCertonaLinkInteractionBySection(linkName, pageType, id);
    }
  };

  return (
    <div
      className={cx(
        contentsAligment === 'vertical'
          ? styles.verticalStackedBlock
          : styles.horizontalStackedBlock,
        { [styles.inDrawer]: inDrawer }
      )}
      data-testid="carousel"
    >
      {!!title && (
        <>
          <h2
            className={cx(styles.partTitle, azCommonStyles['az-padding-bottom-4xs'])}
            data-testid="dynamic-content"
          >
            {title}
          </h2>
          <div className={styles.separator} />
        </>
      )}

      {!!thresholdHeaderText && (
        <>
          <h2 className={styles.thresholdHeaderText} data-testid="dynamic-content">
            {`${lblThresholdContainerHeader}`}
          </h2>
        </>
      )}
      <ul className={styles.cardsList}>
        {partsList.map(
          ({
            id,
            product_image_url,
            product_url_relative,
            product_name,
            total_review_count,
            average_rating,
            rating,
          }) => {
            const skuDetails = skuDetailsData?.find((data: SkuAvailabilty) => {
              return data.skuId === id;
            });
            const bopusQuantity = skuDetails?.availabilityInfoVO.bopusQuantity;
            const sthQuantity = skuDetails?.availabilityInfoVO.sthQuantity;
            const nddQuantity = skuDetails?.availabilityInfoVO.nddQuantity;

            const fulFillmentId =
              !!bopusQuantity && bopusQuantity > 0
                ? FULFILLMENT_METHODS.STOREORDER
                : !!sthQuantity && sthQuantity > 0
                ? FULFILLMENT_METHODS.ONLINEORDER
                : null;

            const onlineOrderFulfillmentId =
              !!nddQuantity && nddQuantity > 0
                ? FULFILLMENT_METHODS.NEXTDAY
                : FULFILLMENT_METHODS.ONLINEORDER;

            const addToCartInfo = {
              skuId: skuDetails?.skuId,
              productId: skuDetails?.attributesInfoVO.productId,
              vehicleId: skuDetails?.catalogVehicleId ? skuDetails.catalogVehicleId : '',
              fulFillmentId: thresholdHeaderText ? onlineOrderFulfillmentId : fulFillmentId,
              brandName: skuDetails?.attributesInfoVO.brandName,
              originalPartTypeId: skuDetails?.attributesInfoVO.originalPartTypeId,
              productPartNumber: skuDetails?.attributesInfoVO.productPartNumber,
              productRepositoryId: skuDetails?.attributesInfoVO.productRepositoryId,
            };

            return (
              <li key={id} className={styles.cardItem}>
                {contentsAligment === 'vertical' ? (
                  <LargePartCard
                    key={id}
                    part={{
                      properties: {
                        seoUrl: product_url_relative,
                        imageUrl: product_image_url,
                        makeModelYearPath: undefined,
                        refPageType: '',
                      },
                      label: product_name,
                      rating,
                      totalReviews: total_review_count,
                      averageRating: average_rating,
                    }}
                    pricingInfo={pricingInfo?.[id]}
                    addToCartData={addToCartInfo}
                    actionHandler={handleCertonaClick}
                    addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                    setAddedCertonaItemObject={setAddedCertonaItemObject}
                    showAddtoCartCTA={showAddtoCartCTA}
                  />
                ) : (
                  <HorizontalPartCard
                    key={id}
                    part={{
                      properties: {
                        seoUrl: product_url_relative,
                        imageUrl: product_image_url,
                        makeModelYearPath: undefined,
                        refPageType: '',
                      },
                      label: product_name,
                      rating,
                      totalReviews: total_review_count,
                      averageRating: average_rating,
                    }}
                    pricingInfo={pricingInfo?.[id]}
                    addToCartData={addToCartInfo}
                    actionHandler={handleCertonaClick}
                    addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                    updateAfterAddtoCartClick={updateAfterAddtoCartClick}
                    setAddedCertonaItemObject={setAddedCertonaItemObject}
                    showAddtoCartCTA={showAddtoCartCTA}
                    inDrawer={inDrawer}
                    cardStyle={'horizontal_stacked'}
                  />
                )}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default StackedPartCardContainer;
